export enum VirtualizersEnum {
  MICROSOFT_REMOTE_DESKTOP_DIRECT_CONNECT = 'RDP_DIRECT',
}

export interface Client {
  client_name: string;
  client_uuid: string;
}

export interface Data {
  TSPLUS: {
    instance: string;
    webport: string;
    window_name: string;
    use_dns: string;
  };
  iframe: string;
  environment_uuid: string;
  ssid: string;
  virtualizer: string;
  cliUuid: string;
}

export interface Iframe {
  type: string;
  iframe_value: string;
}

export interface WebApp {
  name: string;
  url: string;
}

export interface WebAppsFields {
  web_client_apps: WebApp[];
}

export interface ClientsState {
  isLoading: boolean;
  clients: Client[];
  warning_message?: Record<string, any>;
  maintenance_message?: Record<string, any>;
  uuid: string;
  isLoadingAttempt: boolean;
  time: number;
  startingHtml5Instance: boolean;
  data: Data | null;
  iframe: Iframe;
  webApps: WebAppsFields[];
}
