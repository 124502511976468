import { handleActions } from 'redux-actions';
import { InstanceState } from './interfaces';
import Types from './types';

const initialState: InstanceState = {
  pluginUrl: '',
};

export default handleActions(
  {
    [Types.INSTANCE_PLUGIN_SET]: (
      state: InstanceState,
      { payload }
    ): InstanceState => ({
      ...state,
      pluginUrl: payload,
    }),
  },
  initialState
);
