/* eslint-disable max-lines */

export default {
  translation: {
    VERSION_KEY: 'Versión',
    PORTUGUESE_KEY: 'Portugués',
    ENGLISH_KEY: 'Inglés',
    SPANISH_KEY: 'Español',
    SELECT_CLIENT_KEY: 'Seleccione un cliente para continuar',
    HOME_KEY: 'Autosky',
    ENVIRONMENTS_KEY: 'Entornos',
    ACTIONS_KEY: 'Acciones',
    MAXIMUM_LOGIN_ATTEMPTS:
      'Usuario inactivo: Ha superado el número máximo de intentos de inicio de sesión. ¡Por favor, póngase en contacto con el administrador!',
    RECAPTCHA_ERROR_KEY: 'Error en la validación de recaptcha. Tenta novamente',
    CLIENTS_NAME_KEY: 'Clientes',
    MAINTENANCE_KEY: 'Mantenimiento',
    TIME_INSTANCE_KEY: 'Buscando la instancia',
    FIRST_ACCESS_KEY: 'Hola y bienvenidos.',
    FIRST_ACCESS_SECOND_KEY:
      '¿Es la primera vez que accede a la plataforma Autosky?',
    CLICK_HERE_TO_CONNECT_KEY: 'Haga clic para conectarse',
    ADD_PLUGIN_KEY:
      'Para iniciar correctamente la sesión, instale el Plugin de conexión en su ordenador.',
    HAS_NO_PLUGIN_KEY:
      'Si este es su primer acceso, debe instalar el complemento de conexión.',
    HAS_INSTALLED_PLUGIN_KEY: '¿Ya instaló el complemento de conexión?',
    YES_KEY: 'Sí',
    NO_KEY: 'No',
    PENDING_CONNECTIONS_KEY: 'Conexiones pendientes',
    TITLE_FORGOT_PASSWORD_KEY: '¿Olvido la contraseña?',
    FORGOT_PASSWORD_KEY: 'Olvide mi contraseña',
    FORGOT_PASSWORD_TEXT_2_KEY:
      'Ingrese su dirección de correo electrónico a continuación',
    FORGOT_PASSWORD_TEXT_KEY: 'Complete los datos para cambiar su contraseña.',
    FORGOT_PASSWORD2_TEXT_KEY:
      'Evite contraseñas fáciles o simplemente números.',
    BACK_KEY: 'Volver',
    SEND_KEY: 'Enviar',
    CHANGE_KEY: 'Cambio',
    RECOVER_KEY: 'Recuperar',
    WELCOME_KEY: '¡Bienvenido!',
    DESCRIPTION_LOGIN_KEY:
      'Inicie sesión para comenzar a acceder a su sistema en línea, con datos cifrados y almacenados en entornos seguros.',
    NO_ITEMS_FOUND:
      'No se encontró ningún entorno con sus criterios de búsqueda.',
    FORGOT_PASSWORD_HEADER_KEY: `Recuperación de Contraseña`,
    CHANGE_PASSWORD_TITLE_KEY: `Nueva contraseña`,
    FORGOT_PASSWORD_DESCRIPTION_KEY:
      'Ingrese su correo electrónico y haga clic en Recuperar para recibir un correo electrónico de restablecimiento de contraseña.',
    PRIVACY_POLICY_KEY: 'Política de Privacidad',
    CODE_OF_ETHICS_AND_CONDUCT_KEY: 'Términos y Condiciones',
    NEW_PASSWORD_KEY: 'Registre su contraseña',
    NEW_PASSWORD_KEY_2: 'Nueva Contraseña',
    NEW_PASSWORD_DESCRIPTION_KEY:
      'Registre su nueva contraseña a su lado. Utilice letras, números y símbolos para mayor seguridad.',
    HEADER_LOGIN_KEY: 'Rápido y seguro.',
    EMAIL_KEY: 'Correo electrónico',
    OLD_PASSWORD_KEY: 'Contraseña Anterior',
    PASSWORD_KEY: 'Contraseña',
    CONFIRM_PASSWORD_KEY: 'Confirmación de contraseña',
    CONFIRM_NEW_PASSWORD_KEY: 'Confirmar nueva contraseña',
    NO_CLIENTS_KEY: '¡No se encontraron clientes!',
    CONNECT_KEY: 'Conectar',
    SEARCH_KEY: 'Buscar',
    TYPE_YOUR_NEW_PASSWORD_KEY: 'Introduzca su nueva contraseña',
    SEARCH_ENVIRONMENT_KEY: 'Escriba aquí para buscar su entorno...',
    YOU_HAVE_KEY: 'Tienes el total de ',
    ENVIRONMENT_IN_AUTO_SKY_KEY: '',
    SOMETHING_BAD_HAPPENED: 'Ha ocurrido algo extraño. Inténtalo de nuevo.',
    INITIATING_AUTO_SKY_KEY: 'Iniciar la sesión de usuario...',
    THIS_CAN_TAKE_KEY: 'Este procedimiento puede tardar hasta',
    EIGHT_MINUTES_KEY: ' 8 minutos;',
    FIVE_MINUTES_KEY: ' 5 minutos;',
    TWO_MINUTES_KEY: ' 2 minutos;',
    YOU_WILL_BE_REDIRECT_KEY: 'serás redirigido automáticamente',
    OPS_KEY: 'Su entorno está en mantenimiento',
    MAINTENANCE_RUNNING_KEY:
      'El entorno seleccionado no está disponible o se encuentra en mantenimiento por parte de los administradores del sistema.',
    ACCESS_KEY: 'Acceso',
    SESSION_ACTIVE_1_KEY:
      'Hemos identificado que existe una sesión activa para su usuario.',
    SESSION_ACTIVE_2_KEY: '¿Desea finalizar la sesión anterior y continuar?',
    SESSION_ACTIVE_3_KEY:
      'Haga clic en continuar para cerrar la sesión y continuar.',
    WHY_YOU_RECEIVE_THIS_KEY: '¿Por qué recibes este mensaje?',
    SESSION_IDENTIFIED:
      'Es posible que haya sufrido una desconexión momentánea de Internet o que no haya cerrado correctamente su conexión anterior.',
    FINALIZE_PREVIOUS_SESSION:
      'Si esta situación persiste, póngase en contacto con el administrador del sistema.',
    CONTINUE_KEY: 'Continuar',
    WAIT_A_MOMENT_KEY:
      'Espere un momento y no se preocupe, se conectará automáticamente en cuanto estemos listos.',
    LOCATING_ENVIRONMENT:
      'Estamos localizando el mejor espacio para su acceso.',
    REQUIRED_FIELD: '¡Campo obligatorio!',
    ENTER_KEY: 'Iniciar sesión',
    CHANGE_PASSWORD_KEY: 'Cambiar mi contraseña',
    CANCEL_KEY: 'Cancelar',
    VIRTUALIZER_CHOICE_KEY: 'Virtualizador',
    MAX_LENGTH_PASSWORD: 'El máximo de caracteres para la contraseña es 40.',

    USER_NOT_FOUND:
      'Correo electrónico y/o contraseña inválido. Ingrese los datos nuevamente.',
    WRONG_USER_KEY: 'Usuario no encontrado',
    ERROR_REQUEST_KEY: 'Ha ocurrido algo extraño. Inténtalo de nuevo.',
    VERIFY_YOUR_EMAIL_KEY: 'Se ha enviado un mensaje a su correo electrónico.',
    WARNING_MESSAGE: 'Mensaje de advertencia:',
    UPDATED_ACCOUNT_KEY: 'Contraseña alterada con éxito.',
    EMAIL_OR_PASSWORD_DONT_MATCH_KEY:
      'El correo electrónico o la contraseña no se encontraron o no coinciden.',
    EMAIL_INVALID_KEY: '¡Formato de correo inválido!',
    ERROR_CHANGING_PASSWORD_KEY: 'Error al cambiar tu contraseña.',
    ENVIRONMENT_NOT_CONFIGURED_KEY:
      'No hay más licencias disponibles para este entorno. Busque el administrador de su sistema.',
    VIRTUALIZER_NOT_FOUND: 'No se encontró el virtualizador.',
    UPDATE_KEY: 'Actualizar',
    NO_LICENSE_CLIENT: 'Se ha alcanzado el límite de licencia',
    UPDATE_PLUGIN_KEY: 'Actualización del plugin de conexión',
    UPDATE_YOUR_PLUGIN_KEY: 'Actualiza tu complemento',
    UPDATE_YOUR_PLUGIN_TEXT_KEY:
      'Hola, hay una actualización del plugin de conexión para que puedas continuar con tu conexión.',
    UPDATE_YOUR_PLUGIN_TEXT2_KEY2:
      'Pulse el botón CONTINUAR para proceder a la actualización.',
    FOLLOW_THE_STEPS_BELOW_KEY: 'Siga los pasos a continuación.',
    MIN_KEY: 'Mínimo de {{value}} letras',
    MAX_KEY: 'Máximo de {{value}} letras',
    PASSWORD_DOESNT_MATCH: 'Las contraseñas ingresadas son diferentes.',
    PASSWORD_MUST_BE_DIFFERENT:
      'La nueva contraseña debe ser diferente de la contraseña antigua.',

    PASSWORD_MUST_CONTAIN_KEY: 'La contraseña debe contener',
    REQUIRE_UPPERCASE_TEXT_KEY: 'Letras mayúsculas',
    REQUIRE_LOWERCASE_TEXT_KEY: 'Letras minúsculas',
    REQUIRE_NUMBER_TEXT_KEY: 'Números',
    REQUIRE_SYMBOL_TEXT_KEY: 'Caracteres especiales',
    REQUIRE_PASSWORD_DIFF_KEY:
      'La nueva contraseña debe ser diferente de la antigua',
    REQUIRE_MIN_LENGTH_TEXT_KEY: 'Mínimo de {{min_length}} caracteres',
    REQUIRE_MAX_LENGTH_TEXT_KEY: 'Máximo de {{max_length}} caracteres',

    LAUNCHING_KEY: 'Comenzando...',
    PLEASE_CLICK_KEY: 'Clic en',
    AUTO_SKY_PLUGIN_KEY: 'Autosky Plugin',
    SEE_DIALOG_KEY: 'si ve el cuadro de diálogo.',
    IF_NOT_KEY: 'Si el cuadro de diálogo no aparece',
    CLICK_HERE_KEY: 'haga clic aquí ',
    IF_DIALOG_NOT_SHOWING_KEY: 'Si el cuadro de diálogo sigue sin aparecer, ',
    GO_TO_INSTALL_KEY: 'haga clic aquí para seguir los pasos de instalación ',
    PLUGIN_IN_COMPUTER_KEY: 'del plugin en su ordenador.',
    LAUNCH_KEY: 'para volver a mostrar la ventana.',
    DOWNLOAD_AND_RUN_KEY:
      'haga clic aquí para seguir los pasos de instalación.',
    STARTING_HTML5_INSTANCE: 'Su sesión se está preparando...',
    SUB_TEXT_LOADING_VIRTUALIZER_KEY:
      'Migre sus aplicaciones y sistemas cliente-servidor a la nube.',
    LOADING_KEY: 'Cargando',
    ESTABLISHING_CONNECTION_KEY: 'Establecer la conexión',

    CONNECTION_FINISHED_KEY: '¡Conexión establecida!',
    SUCCESS_LOADING_IF_NOT_KEY: 'Si su aplicación aún no se ha abierto',
    SUCCESS_LOADING_CONFIRM_KEY:
      'para confirmar la instalación del complemento',

    CHOOSE_VIRTUALIZER_KEY: 'Seleccione su método de acceso preferido:',
    CHOOSE_VIRTUALIZER_SUB_TEXT_KEY:
      'Cuando seleccione la opción que desee, se le dirigirá a la conexión para su entorno.',
    PC_KEY: 'la Computadora',
    BROWSER_KEY: 'el Navegador',
    OPEN_AT_KEY: 'Abrir en {{content}}',

    INSTALL_KEY: 'Instalar en pc',
    HERE_ENVIRONMENT_KEY:
      'Seleccione el entorno de la lista a continuación para acceder a su sistema.',

    INSTALL_PLUGIN: {
      INSTALLATION_KEY: 'Instalación del complemento de conexión Autosky',
      STEP_TO_STEP_KEY: 'Instrucciones de instalación:',
      IF_NOT_KEY: 'Si la descarga no se realiza correctamente, ',
      IN_CASE_APPLICATION_DIDNT_APPEAR_ONE:
        'Si su aplicación aún no se ha abierto, ',
      CONFIRM_INSTALLATION: 'para confirmar la instalación del complemento',
      TO_CONTINUE_KEY: 'para realizar la instalación manualmente',

      FIRST_STEPS: {
        ONE: '01. Haga clic en el botón de instalación;',
        TWO:
          '02. Se abrirá una ventana para instalar la extensión obligatoria de Google Chrome;',
        THREE:
          '03. Después de activar la extensión, cierre la ventana e instale el archivo ejecutable.',
      },

      LAST_STEPS: {
        ONE: '01. Confirme la descarga automática del archivo de instalación',
        TWO:
          '02. Ejecute el archivo de instalación en su ordenador Windows o MAC',
        THREE:
          '03. Una vez finalizada la instalación en su ordenador, haga clic en SIGUIENTE.',
      },
    },
    NEXT_KEY: 'Siguiente',

    NOT_FOUND_PAGE_KEY: 'Página no encontrada',
    GO_TO_HOME_KEY: 'Ir a la página principal',

    DESCRIPTION_MFA_KEY:
      'Con la plataforma Autosky, usted lleva su software monolítico a la nube, sin tocar una sola línea de código, con la base de datos y los usuarios configurados en unas pocas horas',

    TURN_BACK_KEY: 'Volver',
    AUTHENTICATION_CODE_KEY: 'Introduzca el código de autentificación',
    PANEL_AUTO_SKY: 'Panel Autosky',
    CONFIGURE_MFA_KEY: 'Configure su código de autenticación MFA.',
    INSERT_MFA_KEY: 'Introduzca su código de autenticación MFA',

    MODAL_VERSION_TITLE_KEY: 'Actualización Disponible',
    MODAL_VERSION_CONTENT_KEY:
      'Hay una actualización disponible para su aplicación. Haz clic para actualizar sin perder la sesión actual.',
    MODAL_BUTTON_KEY: 'Actualizar',

    NOT_INPUT_DATA: 'No se han proporcionado datos.',
    USER_NOT_ACTIVE: 'El usuario no está activo',
    INVALID_EMAIL: 'Dirección de correo electrónico no válida.',
    INVALID_PASSWORD: 'Contraseña inválida',
    USER_AUTHENTICATED: 'Usuario autenticado',
    AUTH_FAILURE: 'La autenticación ha fallado.',
    PASSWORD_EXPIRED:
      'La contraseña ha caducado. Redefinir una nueva contraseña',
    PASSWORD_EXPIRES_SOON: 'La contraseña expirará pronto.',
    UNABLE_UPDATE_PASSWORD: 'No se puede actualizar la contraseña.',
    ERROR_UPDATING_PASSWORD: 'Error al actualizar la contraseña.',
    PASSWORD_UPDATED: '¡Contraseña actualizada con éxito!',
    INVALID_TYPE: 'Tipo inválido',
    NOT_NULL: 'Este campo no puede ser nulo.',
    VALIDATOR_FAILED: 'Validación incorrecta.',
    INVALID_VALUE: 'Valor no válido',
    ERROR_IN_ACCESS_VALIDATION:
      'Error al validar el acceso a Active Directory.',
    EXTERNAL_USER: 'Usuario externo',
    SUCCESS: 'Éxito',
    HAS_PENDING_CONNECTION: 'Tiene conexiones pendientes',
    PASSWORD_CHANGE_ERROR: 'Error al cambiar la contraseña.',
    PASSWORD_CHANGE_SUCCESS: '¡Cambio de contraseña exitoso!',
    CONTACT_YOUR_SYSTEM_ADMINISTRATOR:
      'Póngase en contacto con su administrador para cambiar la contraseña',
    EXPIRED_MAIL: 'El correo electrónico ha caducado.',
    ERROR_TO_SEND_DISCONNECT:
      'Error al desconectar usuarios con conexiones pendientes.',
    ENVIRONMENT_MAINTENANCE_WITHOUT_MESSAGE:
      'Mensaje de mantenimiento del entorno configurado sin mensaje.',
    USER_NO_OTP_DATA: 'El OTP no está configurado para su usuario.',
    USER_OTP_ALREADY_CONFIGURED: 'El OTP ya está configurado para su usuario.',
    USER_OTP_INVALID: 'OTP inválido.',
    CLIENT_MAINTENANCE_WITHOUT_MESSAGE:
      'Mensaje de mantenimiento no configurado para el cliente.',
    ENVIRONMENT_WARNING_WITHOUT_MESSAGE:
      'Mensaje de advertencia no configurado para el entorno',
    CLIENT_WARNING_WITHOUT_MESSAGE:
      'Mensaje de advertencia no configurado para el cliente',
    UNSENT_EMAIL: 'Correo electrónico no enviado',
    CLIENT_NOT_FOUND: 'Cliente no encontrado',
    CALL_CLIENT_PLUGIN: 'Llamada al plug-in del cliente',
    SOMETHING_WENT_WRONG: 'Algo no funcionó. Por favor, inténtalo de nuevo.',
    PAYLOAD_NOT_RIGHT: 'Los datos enviados por la carga útil no son correctos.',
    INVALID_RECAPTCHA: 'Recaptcha inválido. Por favor, inténtalo de nuevo.',
    IDP_NOT_FOUND: 'Identity Provider no encontrado',
    IDP_AUTH_LOGIN_REDIRECT_ERROR:
      'No se puede autenticar en el Identity Provider',
    ACS_PROCESS_ASSERTION_ERROR:
      'No se ha podido encontrar el proceso de ACS plug-in.',
    RELAY_STATE_ERROR: 'RELAY_STATE_ERROR',
    INVALID_ACCESS_TOKEN: 'Token de acceso no válido.',
    CORPORATE_TITLE_KEY: 'Iniciar sesión con credenciales corporativas',
    CORPORATE_CREDENTIALS_KEY: 'Credenciales corporativas',
    UNEXPECTED_ERROR: 'Se ha producido un error inesperado',
    CORPORATE_CREDENTIALS_DESCRIPTION_KEY:
      'Para acceder a Autosky usando Credenciales Corporativas, entre no campo abaixo o código de Ambiente Corporativo fornecido pelo seu Administrador',
    SSO_INVALID_ACCESS_TOKEN:
      'Token de acceso no válido, vuelva a iniciar sesión.',
    SSO_CORPORATE_CREDENTIALS_NOT_FOUND:
      'Credencial corporativa no encontrada.',
    SSO_ERROR_ON_GET_CORPORATE_CREDENTIALS:
      'Error al obtener la credencial corporativa, comuníquese con el administrador',
    SSO_ERROR_ON_PREPARING_CORPORATE_CREDENTIALS:
      'Error al identificar la credencial corporativa, comuníquese con el administrador.',
    SSO_CORPORATE_CREDENTIAL_NOT_ENABLED:
      'La credencial corporativa identificada no está activa, comuníquese con el administrador.',
    SSO_AUTH_LOGIN_REDIRECT_ERROR:
      'Error al redireccionar al sitio externo de credenciales corporativas, comuníquese con el administrador.',
    SSO_ACS_PROCESS_ASSERTION_ERROR:
      'Error al procesar la respuesta de la credencial corporativa externa, comuníquese con el administrador',
    SSO_ACS_RESPONSE_ASSERTION_ERROR:
      'El documento de respuesta de la credencial corporativa no es válido, comuníquese con el administrador.',
    SSO_ACS_RESPONSE_HAS_EXPIRED:
      'Se agotó el tiempo de espera del proceso de inicio de sesión de la credencial corporativa, inténtelo de nuevo',
    SSO_ACS_RESPONSE_IS_INVALID:
      'Error al validar la respuesta de la credencial corporativa. Vuelva a intentarlo',
    SSO_ERROR_ON_GET_SAML_ATTRIBUTES:
      'Error al obtener los detalles del usuario a través de la credencial corporativa. Vuelva a intentarlo',
    SSO_MISSING_SAML_NAME_ID_CLAIM:
      'El sistema de credenciales corporativas externas no devolvió el atributo [ID de nombre] con un nombre de usuario, inicio de sesión o UPN únicos. Comuníquese con el administrador',
    SSO_MISSING_SAML_GIVEN_NAME_CLAIM:
      'El sistema de credenciales corporativas externas no devolvió el atributo [Nombre dado] con el nombre del usuario. Comuníquese con el administrador',
    SSO_MISSING_SAML_APELLIDO_CLAIM:
      'El sistema de credenciales corporativas externas no devolvió el atributo [Apellido] con el apellido del usuario. Póngase en contacto con el administrador.',
    SSO_MISSING_SAML_EMAIL_ADDRESS_CLAIM:
      'El sistema de credenciales corporativas externas no devolvió el atributo [Correo electrónico] con la dirección de correo electrónico del usuario. Comuníquese con el administrador',
    SSO_SAML_NAME_ID_CLAIM_TOO_LARGE:
      'El sistema de credenciales corporativas externo devolvió el atributo [ID de nombre] con el nombre único del usuario, inicio de sesión o UPN, con más caracteres permitidos. Comuníquese con el administrador',
    SSO_SAML_GIVEN_NAME_CLAIM_TOO_LARGE:
      'El sistema de credenciales corporativas externas devolvió el atributo [Nombre dado] con el nombre del usuario, con más caracteres permitidos. Comuníquese con el administrador',
    SSO_SAML_APELLIDO_CLAIM_TOO_LARGE:
      'El sistema de credenciales corporativas externas devolvió el atributo [Apellido] con el apellido del usuario, con una mayor cantidad de caracteres permitidos. Comuníquese con el administrador.',
    SSO_SAML_EMAIL_ADDRESS_CLAIM_TOO_LARGE:
      'El sistema de credenciales corporativo externo devolvió el atributo [Correo electrónico] con la dirección de correo electrónico del usuario, con más caracteres permitidos. Comuníquese con el administrador',
    SSO_USER_IS_BEING_CREATED_WAIT_IT:
      'El usuario de la credencial corporativa ya se está creando en otra sesión, inténtelo de nuevo',
    USER_NOT_PERMITTED_SSO_CLIENT:
      'O acesso não pôde ser estabelecido pois o cliente possui habilitada a configuração de uso de credenciais corporativas.',
    USER_NOT_PERMITTED_SSO_ENABLED:
      'Acesso inválido usando-se credenciais corporativas.',
    USER_ACCESS_IS_RESTRICTED: 'El acceso del usuario está restringido',
    ACCESS_RESTRICTED_TITLE_KEY: 'No está autorizado para acceder ahora',
    ACCESS_RESTRICTED_IP_KEY:
      'Consulte con el administrador del sistema si su dirección IP {{ip}} tiene acceso a la plataforma',
    ACCESS_RESTRICTED_SCHEDULE_KEY:
      'Su acceso se liberará a las {{start}} ({{timeZone}}), según la configuración definida por el administrador de la cuenta',
    DO_YOU_CHANGE_YOUR_PASSWORD:
      '¿Quieres restablecer tu contraseña de acceso ahora?',
    CHANGE_PASSWORD_NOW: 'Sí, establecer ahora',
    NOT_CHANGE_PASSWORD_NOW: 'No, establecer más adelante',
    DAYS_TO_CHANGE_PASSWORD: 'Su contraseña vencerá en {{days}} {{letter}}',
    DAY_KEY: 'día',
    DAYS_KEY: 'días',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_WRONG_CONFIG:
      'Error al obtener la credencial corporativa. Configuración no válida. Comuníquese con el administrador',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_MISSING_ATTRIBUTE:
      "Error al obtener la credencial corporativa. Falta el atributo 'Grupo'. Comuníquese con el administrador",
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_UNKNOWN_FORMAT:
      'Error al obtener la credencial corporativa. El atributo tiene un formato desconocido. Comuníquese con el administrador',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_FORBIDDEN:
      'Error al obtener la credencial corporativa. El usuario no tiene acceso. Comuníquese con el administrador',

    FIRST_ACCESS_CONFIRM_KEY: 'Instalar ahora',
    FIRST_ACCESS_CANCEL_KEY: 'Ya tengo el plugin instalado',
    COPY_KEY: 'Copiar',
    COPIED_KEY: 'Copiado',
    CLICK_TO_COPY_KEY: 'Haga clic aquí para copiar',
    MFA_TYPE_ERROR: 'Tipo de autenticación incorrecto',
    DUO_SETTINGS_NOT_FOUND: 'Configuración de autenticación DUO no encontrada',
    DUO_STATE_MISMATCH: 'Token de acceso DUO incorrecto',
    DUO_ERROR_TO_AUTHENTICATE: 'Error de autenticación de usuario DUO',
    DESKTOP_APPS_KEY: 'Aplicaciones de Desktop',
    DEFAULT_ACCESS_KEY: 'Acceso estándar',
    WEB_APPS_KEY: 'Aplicaciones Web',
  },
};
